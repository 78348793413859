var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "confirmation-header-container" }, [
    _c(
      "div",
      {
        staticClass:
          "confirmation-content d-flex justify-content-between align-items-center",
      },
      [
        _c("div", { staticClass: "confirmation-text" }, [
          _c("h4", { staticClass: "confirmation-number" }, [
            _vm._v(
              "\n        Thank you for your order! You can track your order on the 'Filing Orders' page from the home page.\n      "
            ),
          ]),
          _c("h4", { staticClass: "confirmation-number" }, [
            _vm._v("\n        Your order confirmation number is "),
            _c("span", [_vm._v(_vm._s("#" + _vm.invoiceNumber))]),
          ]),
          _c("p", [
            _vm._v(
              "\n        The following items were included in your order:\n      "
            ),
          ]),
          _c(
            "ul",
            _vm._l(_vm.invoice.invoice_line_items, function (item) {
              return _c("li", { key: item.id }, [
                _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(item.product.name) +
                      " for " +
                      _vm._s(item.sub_description_lines[0]) +
                      "\n          "
                  ),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("picture", [
          _c("source", {
            attrs: {
              srcset: require("@images/illustrations/products/car-teal-500px.webp"),
              type: "image/webp",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@images/illustrations/products/car-teal-500px.png"),
              alt: "Car illustration",
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }